import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import { db } from "utils/firebase";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import hexRgb from 'hex-rgb';
import { CreateToken, VerifyToken } from "utils/JwtConfig";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { REMOVE_USER, selectUser } from "redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_PAGE, selectIsPageIn } from "redux/slice/tokenSlice";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ContactUserPage from 'containers/ContactUsePage/ContactUserPage';
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageSingOut from "containers/PageSingOut/PageSingOut";
import PageLogin from "containers/PageLogin/PageLogin";
import ForgotPassPage from "containers/ForgotPassPage/ForgotPassPage";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import './../styles/__theme_colors.scss'
import Loader from "components/Loader";
import EditPostPage from "containers/EditPostPage/EditPostPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  // { path: "/#", exact: true, component: PageHome },
  // { path: "/home-1-header-2", exact: true, component: PageHome },
  // { path: "/home-2", component: PageHome2 },
  // { path: "/home-3", component: PageHome3 },
  // //
  // { path: "/listing-stay", component: ListingStayPage },
  // { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/post/:id", component: ListingStayDetailPage, 
    matchPath: (path, params) => {
      return path.match(/^\/post\/(\d+)$/);
    }
  },
  //
  // {
  //   path: "/listing-experiences",
  //   component: ListingExperiencesPage,
  // },
  // {
  //   path: "/listing-experiences-map",
  //   component: ListingExperiencesMapPage,
  // },
  // {
  //   path: "/listing-experiences-detail",
  //   component: ListingExperiencesDetailPage,
  // },
  //
  // { path: "/listing-car", component: ListingCarPage },
  // { path: "/listing-car-map", component: ListingCarMapPage },
  // { path: "/listing-car-detail", component: ListingCarDetailPage },
  // //
  // { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  // { path: "/listing-real-estate", component: ListingRealEstatePage },
  // //
  // { path: "/listing-flights", component: ListingFlightsPage },
  // //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  // { path: "/account", component: AccountPage },
  // { path: "/account-password", component: AccountPass },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-billing", component: AccountBilling },
  // //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  // { path: "/add-listing-2", component: PageAddListing2 },
  // { path: "/add-listing-3", component: PageAddListing3 },
  // { path: "/add-listing-4", component: PageAddListing4 },
  // { path: "/add-listing-5", component: PageAddListing5 },
  // { path: "/add-listing-6", component: PageAddListing6 },
  // { path: "/add-listing-7", component: PageAddListing7 },
  // { path: "/add-listing-8", component: PageAddListing8 },
  // { path: "/add-listing-9", component: PageAddListing9 },
  // { path: "/add-listing-10", component: PageAddListing10 },
  //
  // { path: "/contact", component: PageContact },
  // { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/singout", component: PageSingOut },
  { path: "/forgot-pass", component: ForgotPassPage },
  { path: "/subscription", component: PageSubcription },
  { path: "/contact-user/:id", component: ContactUserPage },
  { path: "/edit-post", component: EditPostPage }
  //
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const Token = useSelector(selectIsPageIn);
  const [currentToken, setCurrentToken] = useState(Token);
  const [isLoading, setIsLoading] = useState(true);


  function interpolateColor(color1: number[], color2: number[], weight: number): string {
    const interpolatedColor = color1.map((component1, index) => {
      const component2 = color2[index];
      const interpolatedComponent = component1 + (component2 - component1) * weight;
      return Math.round(interpolatedComponent);
    });

    return `${interpolatedColor.join(', ')}`;
  }

  function getCustomColorScaleFromDarkTo900(baseColor: string): { scale: number, color: string }[] {
    const baseRGB = baseColor.match(/\d+/g)?.map(Number);

    if (!baseRGB) {
      throw new Error('Invalid color format');
    }

    const colorScale = [];

    for (let scale = 900; scale >= 600; scale -= 100) {
      const weight = (900 - scale) / 300;
      const interpolatedColor = interpolateColor([0, 0, 0], baseRGB, weight);
      colorScale.push({ scale, color: interpolatedColor });
    }

    return colorScale;
  }

  function getCustomColorScaleFromLightTo50(baseColor: string): { scale: number, color: string }[] {
    const baseRGB = baseColor.match(/\d+/g)?.map(Number);

    if (!baseRGB) {
      throw new Error('Invalid color format');
    }

    const colorScale = [];

    const intervals = [50, 100, 200, 300, 400, 500];

    for (const scale of intervals) {
      if (scale < 50 || scale > 500) {
        throw new Error('Invalid scale value');
      }

      const weight = (600 - scale) / 550;
      const interpolatedColor = interpolateColor(baseRGB, [255, 255, 255], weight);
      colorScale.push({ scale, color: interpolatedColor });
    }

    return colorScale;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUrl = window.location.href;
        const splitUrl = currentUrl.split('/');

        const dataPage = await db.collection('tenants').doc(splitUrl[2]).get();
        console.log("🚀 ~ file: index.tsx:204 ~ fetchData ~ dataPage:", dataPage.exists)
        if (dataPage.exists) {
          const tempDataPage = dataPage.data();
          const metadaPage = await db.collection('portals').doc(`${tempDataPage?.value}`).get();

          if (user !== null) {
            const expire = await VerifyToken(user);
            if (expire) {
              dispatch(REMOVE_USER({ user: null }));
            }
          }

          const tempMetadaPage = metadaPage.data() ?? {};
          let link = document.querySelector("link[rel~='icon']");
          if (link instanceof HTMLLinkElement) { 
            link.href = tempMetadaPage.media_favicon;
          }

          tempMetadaPage.key = metadaPage.id;
          const primaryColor = hexRgb(tempMetadaPage.metadata.color_primary, { format: 'css' });
          const secondaryColor = hexRgb(tempMetadaPage.metadata.color_secondary, { format: 'css' });

          const root = document.documentElement;
          const colorScaleFromDarkTo900Primary = getCustomColorScaleFromDarkTo900(primaryColor);
          colorScaleFromDarkTo900Primary.forEach((CadaColor) => {
            root.style.setProperty(`--c-primary-${CadaColor.scale}`, CadaColor.color)
          })

          const colorScaleFromLightTo50Primary = getCustomColorScaleFromLightTo50(primaryColor);
          colorScaleFromLightTo50Primary.forEach((CadaColor) => {
            root.style.setProperty(`--c-primary-${CadaColor.scale}`, CadaColor.color)
          })

          const colorScaleFromDarkTo900Secondary = getCustomColorScaleFromDarkTo900(secondaryColor);
          colorScaleFromDarkTo900Secondary.forEach((CadaColor) => {
            root.style.setProperty(`--c-secondary-${CadaColor.scale}`, CadaColor.color)
          })

          const colorScaleFromLightTo50Secondary = getCustomColorScaleFromLightTo50(secondaryColor);
          colorScaleFromLightTo50Secondary.forEach((CadaColor) => {
            root.style.setProperty(`--c-secondary-${CadaColor.scale}`, CadaColor.color)
          })

          const token = await CreateToken(tempMetadaPage);
          const expireTokenPage = await VerifyToken(currentToken);

          if (currentToken === null || expireTokenPage) {
            dispatch(SET_ACTIVE_PAGE({ token }));
            setCurrentToken(token);
            window.location.reload();
          }
        } else {
          window.location.replace('https://vayavaya.net');
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchData();
  }, [currentToken])

  useEffect(()=>{
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [])

  return (
    <BrowserRouter>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <ScrollToTop />
          <SiteHeader />

          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<Page404 />} />
          </Routes>

          {/* {WIN_WIDTH < 768 && <FooterNav />} */}
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export default MyRoutes;
