import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
    apiKey: 'AIzaSyDemDA9YjJIuFKAeyQi_-ECCb4Ln_11HXo',
    authDomain: 'vayavaya-app.firebaseapp.com',
    projectId: 'vayavaya-app',
    storageBucket: 'vayavaya-app.appspot.com',
    messagingSenderId: '243701864497',
    appId: '1:243701864497:web:4b9af1f6d0209fc4e19d10',
});

const db = firebase.firestore();
const storage = getStorage(app);
const authentication = app.auth()

export { app, db, storage, authentication };
